$container-padding: 2vw;

.container {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  max-height: 100vh;
  min-height: 100vh;
  padding: $container-padding;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: $container-padding;
  padding-top: 2vh;
}
