@import '../../../../shared/styles/utils';

.container {
  display: flex;
  flex-direction: column;
}

header.header {
  background-color: #181d29;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: $box-shadow-medium;
  display: flex;
  height: 115px;
  position: sticky;
  top: 0;
  z-index: 1;

  .profileData {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .profilePicture {
    background-color: #000;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    height: 60px;
    margin-right: getSpacing(2);
    min-width: 60px;
    width: 60px;
  }
}

.content {
  margin: auto;
  max-width: 800px;
  padding: 20px;
}

.separator {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  margin: getSpacing(6) 0 getSpacing(3);
}
