.container {
  padding: 35px;
}

.headingContainer {
  align-items: center;
  border-bottom: 1px solid #2d364d;
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
  padding-bottom: 25px;
}

.title {
  font-size: 32px;
}

.teammatesContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.card {
  background: #1e2433;
  border: 1px solid #181f32;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
  margin-right: 24px;
  padding: 25px;
}

.avatar {
  border-radius: 100%;
  height: 48px;
  margin-right: 12px;
  width: 48px;
}

.text {
  align-items: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 78px;
}

.cardTitle {
  margin-bottom: 7px;
}
