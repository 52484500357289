.container {
  display: flex;
  position: relative;
}

.iframe {
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 50px;
  box-sizing: border-box;
  position: absolute;
  transform-origin: top left;
  transition: width 0.3s, height 0.3s, transform 0.3s;
}

.content {
  animation-delay: 1s;
}
