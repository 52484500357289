.container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.animationContainer {
  padding: 0 !important;
}

.contentContainer {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
}

.content {
  display: flex;
  height: 100%;
  max-height: 350px;
  opacity: 0;
  position: absolute;
  transform: translateY(120%);
  transition: opacity 0.5s, transform 0.8s;
}

.appearActive {
  transform: translateY(40%);
}

.contentEnter,
.contentEnterDone {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
}

.contentExit,
.contentExitActive {
  opacity: 0;
  transform: translateY(-120%);
}
