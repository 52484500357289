@import '../../styles/utils';

$size: getSpacing(3);

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: $size;
  max-width: $size;
  min-height: $size;
  min-width: $size;
}
