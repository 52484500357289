@import '../../../shared/styles/variables';

.container {
  background-color: #1e2433;
  border-radius: 15px;
  box-shadow: $box-shadow-medium;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  min-width: 250px;
}

.header {
  display: flex;
  min-height: 50px;

  .headerTitle {
    flex: 1;
    position: relative;
  }

  .titleContainer {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    padding-left: 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  .backgroundContainer {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;

    svg.titleBackground {
      border-top-left-radius: 15px;
      height: 100%;
      width: 100%;
    }
  }

  .remainingBackgroundContainer {
    width: 60px;

    svg.remainingBackground {
      border-top-right-radius: 15px;
    }
  }
}

main.content {
  padding: 10px;

  .sectionContent {
    opacity: 0.7;
    padding-left: 10px;
  }
}
