@use 'sass:math';

@import '../../styles/variables';

$logo-to-company-name-font-size-ratio: 5.5;

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .svg {
    transition: width $short-time, height $short-time;
  }

  .textContainer * {
    transition: font-size $short-time;
  }
}

@mixin company-name-size($font-size) {
  .textContainer * {
    // Text size shouldn't be manipulated outside of our reusable
    // components. This is a hack to match the real logo design.
    color: $primary-color-old-dark !important;
    font-size: $font-size !important;
  }
}

.smallLogo {
  @include company-name-size(
    math.div($small-logo-width, $logo-to-company-name-font-size-ratio)
  );

  .svg {
    width: $small-logo-width;
  }
}

.mediumLogo {
  @include company-name-size(
    math.div($medium-logo-width, $logo-to-company-name-font-size-ratio)
  );

  .svg {
    width: $medium-logo-width;
  }
}

.largeLogo {
  @include company-name-size(
    math.div($large-logo-width, $logo-to-company-name-font-size-ratio)
  );

  .svg {
    width: $large-logo-width;
  }
}
