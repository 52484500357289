@import '../../../shared/styles/utils';

.verticalStatsContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1;
    margin: getSpacing(1);
    min-width: 200px;
  }
}

.buttonContainer {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  padding: getSpacing(2);
}

.row {
  display: flex;
}

.verticalStatSummary {
  border-left-color: $burnout-likelihood-color !important;
  width: 170px;
}

.card {
  border-color: $burnout-likelihood-color !important;
  border-width: 2 !important;
}
