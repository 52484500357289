@import '../../styles/variables';

.container {
  border: 1px solid #2d364d;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  min-height: 50px;
  padding: 10px 15px;

  &.withIcon {
    padding-left: 10px;
  }

  &.highlight {
    border-color: #fff;
  }
}

.clickable {
  cursor: pointer;
  transition: transform 0.3s;
  user-select: none;

  &:hover {
    transform: scale(1.02);
  }
}

.titleContainer > * {
  margin-bottom: 0;
}

.content {
  align-items: center;
  display: flex;
  flex: 1;

  .icon {
    align-items: center;
    display: flex;
    margin-right: 10px;
  }
}
