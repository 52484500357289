@import '../../../../shared/styles/utils';

.container {
  display: flex;
  flex-direction: column;
}

.chartContainer {
  flex: 1;
  min-width: 50%;
}

.buttonContainer {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: getSpacing(2);
}

.sectionConnector {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 20px;
}

@mixin colors-for-stat-type(
  $border-color,
  $separator-light-color,
  $separator-dark-color
) {
  .tipCard {
    border-color: $border-color;
    border-width: 2px;
  }

  .roadmapSeparatorLightColor {
    fill: $separator-light-color;
  }

  .roadmapSeparatorDarkColor {
    fill: $separator-dark-color;
  }
}

.roadmapVisibility {
  @include colors-for-stat-type(#a772d0, #a772d0, #711fb0);
}

.timePressure {
  @include colors-for-stat-type(#ff774b, #ff774b, #b93214);
}

@media only screen and (max-width: $mobile-breakpoint) {
  .buttonContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
