@import '../../../../shared/styles/utils';

.separator {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  margin: getSpacing(3) 0 getSpacing(3);
}

.baselineContainer {
  margin: auto;
  max-width: 700px;
}

.templateContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: 1000px;
}

.separatorContainer {
  margin: auto;
  max-width: 750px;
}

.cardContainer {
  margin-bottom: getSpacing(2);
  margin-right: getSpacing(2);
  max-width: 250px;

  &:last-child {
    margin-right: 0;
  }
}
