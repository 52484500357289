@import '../../../../shared/styles/utils';

.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1150px;
}

header.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: getSpacing(3) getSpacing(3);
}

.content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.templateContainer {
  box-sizing: border-box;
  max-width: 33%;
  padding: getSpacing(2);
}
