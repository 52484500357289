@import '../../../../shared/styles/utils';

.container {
  background-color: $lighter-background-color;
  border-radius: $border-radius-large;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: getSpacing(2);
  width: $survey-editor-panel-max-width;
}

.footerForScrollableView {
  box-shadow: 0 -5px 10px rgb(0, 0, 0, 0.4);
  z-index: 1;
}

main.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

footer.footer {
  display: flex;
  padding-top: getSpacing(2);
}

.textFieldSettings {
  align-items: center;
  display: flex;
  padding-top: getSpacing(1);

  > *:first-child {
    flex: 1;
  }

  > *:nth-child(2) {
    flex: 2;
  }
}
