.container {
  flex: 1;
  min-height: 250px;
  min-width: 300px;
  position: relative;
}

.content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
