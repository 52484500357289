@import './shared/styles/variables';
@import './shared/styles/globalAnimations';

/* These fonts should mirror the ones in .storybook/*-head.html */
@font-face {
  font-family: GothamRound;
  font-weight: bold;
  src: url('./assets/fonts/gothamrnd-bold.otf');
}

@font-face {
  font-family: GothamRound;
  font-weight: lighter;
  src: url('./assets/fonts/gothamrnd-light.otf');
}

@font-face {
  font-family: GothamRound;
  font-weight: normal;
  src: url('./assets/fonts/gothamrnd-regular.otf');
}

html,
body {
  background-color: $background-color;
}

body,
#root {
  min-height: 100vh;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
