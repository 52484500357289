@import '../../../shared/styles/utils';

$header-height: 115px;
$tab-height: 60px;
$content-width: 900px;

:export {
  burnoutLikelihoodColor: $burnout-likelihood-color;
  roadmapVisibilityColor: $roadmap-visibility-color;
  trustAndBelongingColor: $trust-and-belonging-color;
  workflowSatisfactionColor: $workflow-satisfaction-color;
}

.content {
  flex-direction: column;
  margin: auto;
  max-width: $content-width;
  padding-bottom: getSpacing(3);
}

header.header {
  background-color: #181d29;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: $box-shadow-medium;
  display: flex;
  flex-direction: column;
  height: $header-height;
  margin-bottom: getSpacing(8);
  position: relative;
}

.pageTitleContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: getSpacing(2);
}

.tabsWrapper {
  display: flex;
  height: $tab-height;
  left: 0;
  position: absolute;
  right: 0;
  top: calc(#{$header-height} - #{$tab-height} / 2);
}

.tabsContainer {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: auto;
}
