.row {
  cursor: text;
  display: flex;
  padding: 10px 0;

  > *:nth-child(2) {
    flex: 1;
  }

  > .textContainer {
    opacity: 0;
    overflow-x: hidden;
    transition: opacity 0.3s, width 0.3s;
    width: 0;
  }

  &.displayingIndex {
    > .textContainer {
      opacity: 1;
      width: 35px;
    }
  }
}
