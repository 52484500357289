article.info {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-width: 120px;
  width: 15%;

  .infoMainText {
    display: flex;
  }

  .iconContainer {
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 940px) {
  article.info .infoMainText {
    flex-direction: column;
  }
}
