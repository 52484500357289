@import '../../styles/utils';
@import '../../styles/variables';

.container {
  background: #08070c;
  display: flex;
  flex: 1;
  height: 100%;
  max-height: 100vh;
  min-height: 100vh;
  overflow-y: hidden;

  > main {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 60vh;
    margin: auto;
    max-height: 1000px;
    padding: 10vh 5vh;

    > .logoContainer {
      margin-bottom: getSpacing(2);
    }
  }

  > section.animationSection {
    flex: 1;
  }

  section.animationSection {
    align-items: center;
    background-color: $light-background-color;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: relative;

    .wave {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: scaleX(0.5);
      transform-origin: left;
      transition: transform 0.3s;
      z-index: 1;
    }
  }
}

@media only screen and (max-width: $laptop-breakpoint) {
  .container > main {
    flex: 0.8;
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .container > main {
    padding: 10vh ​3vw;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  $animation-section-height: 40vh;

  .container {
    flex-direction: column-reverse;

    > main {
      max-height: 100vh - $animation-section-height;
      padding: 5vh 1vw;
    }
  }

  .logoContainer {
    left: 20px;
    position: absolute;
    top: 20px;
    z-index: 2;
  }

  section.animationSection {
    height: $animation-section-height;
    max-height: $animation-section-height;
    min-height: $animation-section-height;
    overflow-y: hidden;
    padding: 0 $spacing * 4 $spacing * 4;

    .wave {
      height: unset !important;
      right: 0;
      top: unset !important;
      transform: none !important;
    }
  }
}
