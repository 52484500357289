@import '../../../../../../shared/styles/variables';

.selectedDay {
  background-color: $primary-color-old !important;
  border-radius: 0 !important;
  color: $text-on-light-color !important;
  font-weight: bold !important;
  position: relative;

  &:hover,
  &:focus {
    background-color: $primary-color-old-dark !important;
  }
}

.isFirstDay {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.isLastDay {
  border-bottom-right-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.dayIsInBetweenSelection {
  width: 40px !important;
}
