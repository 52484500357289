@import '../../../styles/variables';

.datePicker {
  color: $text-color;

  > div {
    background-color: transparent !important;
  }
}

.hideNavigation {
  /* stylelint-disable-next-line selector-class-pattern */
  :global(.MuiCalendarPicker-root) {
    > div:first-child {
      display: none;
    }
  }
}
