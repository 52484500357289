@import '../../../../../../shared/styles/utils';

.container {
  display: flex;
  flex-direction: column;
}

.fieldContainer {
  padding-top: getSpacing(1);
}
