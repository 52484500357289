@import '../../styles/utils';

.container {
  background-color: #12161f;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: $border-radius-large;
}

.header {
  background-color: $primary-color;
  border-radius: $border-radius-large $border-radius-large 0 0;
  padding: getSpacing(1) getSpacing(2);
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: getSpacing(3) getSpacing(2);
}
