@import '../../../../shared/styles/utils';

header.header {
  background-color: #181d29;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: $box-shadow-medium;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 115px;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.titles {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 25px;
}

.selects {
  display: flex;
  justify-content: center;
}

.selectAudience {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  width: 400px;
}

.selectBatchDate {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  width: 400px;
}

.charts {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 900px;
  padding: 35px;
}
