@import '../../../styles/utils';

.container {
  background-color: rgba(0, 192, 170, 0.25);
  border-radius: $border-radius-large;
  display: inline-flex;
  padding: 1px getSpacing(2);
  transform: scale(0.9);

  &.isDown {
    background-color: rgb(255, 87, 34, 0.3);
  }
}
