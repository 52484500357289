@import '../../../shared/styles/utils';

.verticalStatsContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1;
    margin: getSpacing(1);
    min-width: 200px;
  }
}

.buttonContainer {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  padding: getSpacing(2);
}

.verticalStatHeader {
  background-color: $trust-and-belonging-color !important;
}

.card {
  border-color: $trust-and-belonging-color !important;
  border-width: 2 !important;
}
