.container {
  display: flex;
  flex: 1;
}

.panelsContainer {
  box-sizing: border-box;
  display: flex;
  max-width: 500px;
  min-width: 300px;
  padding: 2vw;
  width: 28vw;

  &.questionsBrowserContainer {
    flex-direction: column;
    justify-content: center;
  }
}

main.content {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.emulator {
  height: 75vh;
  max-width: 50vw;
}
