.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 100vh;
  min-height: 100vh;
}

.tabsContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s;
  z-index: 1;

  &.highlighted {
    background-color: #000;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
}
