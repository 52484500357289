@import '../../../../../../shared/styles/utils';
@import '../../../../../../shared/styles/variables';

$width: getSpacing(7);
$height: getSpacing(4);

.container {
  align-items: center;
  border-radius: $border-radius-medium;
  display: flex;
  height: $height;
  justify-content: center;
  max-height: $height;
  max-width: $width;
  min-height: $height;
  min-width: $width;
  transition: background-color 0.3s;
  width: $width;
}
