@import '../../../../shared/styles/variables';

.container {
  display: flex;

  > * {
    flex: 1;

    &:first-child {
      margin-right: 20px;
    }
  }
}

@media only screen and (max-width: 530px) {
  .container {
    flex-direction: column;

    > *:first-child {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
