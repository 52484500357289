@import '../../../../shared/styles/utils';
@import '../../../../shared/styles/variables';

.container {
  background-color: $lighter-background-color;
  border-radius: $border-radius-large;
  display: flex;
  flex-direction: column;
  max-width: $survey-editor-panel-max-width;
}

.sectionNameContainer {
  background-color: $lighter-background-color;
  padding-left: getSpacing(2);
  padding-top: getSpacing(1);
  position: sticky;
  top: 0;
}

header.header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 5px 15px;
}

main.content {
  max-height: 80vh;
  overflow-y: auto;
}
