@import '../../../../shared/styles/utils';

.container {
  background-color: $primary-color-300;
  border-radius: $border-radius-large;
  box-shadow: 0 0 24px $primary-color-300;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
}

.content {
  color: #fff !important;
  flex: 1;
  min-width: 200px;
  padding: getSpacing(2) getSpacing(3);
}

.design {
  display: flex;
  min-width: 200px;

  > * {
    flex: 1;
  }
}
