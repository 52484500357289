@import '../../styles/utils';

header.header {
  background-color: #0d0c12;
  display: flex;
  height: 80px;
}

.navContent {
  align-items: center;
  display: flex;
  flex: 1;
  margin: auto;
  max-width: $layout-max-width;
}

.searchContainer {
  flex: 1;
  margin-right: 16px;
  max-width: $global-search-width;
  transition: width 0.3s;

  &:focus {
    width: 400px;
  }
}

.outletContainer {
  transition: filter 0.3s;

  &.blurredContainer {
    filter: blur(6px);
  }
}

.logoContainer {
  align-items: center;
  display: flex;
  flex: 0.2;
  justify-content: center;
  margin-right: 20px;
}

.navigationLinks {
  color: #fff;
  flex: 1;
}

.navigationLink {
  color: #fff;
  margin-right: 28px;
  text-decoration: none;
}

.isSelected {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.rightContainer {
  align-items: center;
  display: flex;
  flex: 0.6;
}

.notificationBellContainer {
  align-items: center;
  background: #151b24;
  border-radius: 100px;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 16px;
  width: 48px;

  * > {
    height: 50%;
  }
}

.profileContainer {
  align-items: flex-end;
  background: #151b24;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 16px;
  width: 48px;

  * > {
    height: 85%;
  }
}
