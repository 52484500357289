.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.repeatsContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
