.container {
  min-width: 300px;
  overflow-y: auto;
}

.content {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s, opacity 0.3s;
}

.contentEnter,
.contentEnterDone {
  max-height: 100px;
  opacity: 1;
}

.contentExit,
.contentExitActive {
  max-height: 0;
  opacity: 0;
}
