@import '../../../../../../shared/styles/utils';
@import '../../../../../../shared/styles/variables';

.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: getSpacing(7);
  padding-left: getSpacing(2);
  transition: background-color $short-time;
  user-select: none;

  > .nameContainer {
    flex: 1;
    margin-left: getSpacing(2);
  }

  &:hover,
  &.selected {
    background-color: $dark-primary-color;
  }
}
