@import '../../styles/utils';

$overlay-animation-duration: 0.3s;

.overlay {
  align-items: center;
  animation: error-container-enter $overlay-animation-duration forwards;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  padding: getSpacing(5);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.container {
  animation: error-container-enter $overlay-animation-duration
    $overlay-animation-duration forwards;
  background-color: $light-theme-background-color;
  border-radius: $border-radius-larger;
  box-sizing: border-box;
  max-width: 800px;
  min-width: 250px;
  opacity: 0;
  padding: getSpacing(4);
  position: relative;
  transform: translateY(100px);
  width: 100vw;

  p {
    color: #212121 !important;
  }
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-larger;
  margin-bottom: getSpacing(1);
  margin-right: getSpacing(1);
  padding: getSpacing(1) getSpacing(2);
}

.closeIconContainer {
  position: absolute;
  right: getSpacing(1);
  top: getSpacing(1);
}

header.header {
  display: flex;
}

.nameAndActions {
  flex: 1;
}

.thumbnailContainer {
  background-color: #1e2433;
  border-radius: $border-radius-larger;
  display: flex;
  height: 230px;
  padding: 10px;
  width: 280px;

  .thumbnail {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: $border-radius-larger;
    flex: 1;
  }
}

.tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
}

.tab {
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  cursor: pointer;
  padding: 0 getSpacing(2);
  transition: border-bottom-color 0.3s;

  &.selectedTab {
    border-bottom-color: $primary-color;

    p {
      font-weight: bold;
    }
  }
}

.question {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-large;
  display: flex;
  margin-bottom: getSpacing(1);
  min-height: 45px;
  padding-left: getSpacing(1);
}

@keyframes error-container-enter {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
