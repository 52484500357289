.container {
  position: relative;

  > div {
    display: flex !important;
  }
}

.iconButton {
  transition: opacity 0.3s;

  &.hiddenIconButton {
    opacity: 0;
    pointer-events: none !important;
  }
}
