@import '../variables';

.base {
  color: $text-color;
  font-family: GothamRound !important;
  margin: $text-margin;
}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.noMargin {
  margin: 0;
}
