@import '../../../../../../../shared/styles/variables';

$border-radius: 50px;

@mixin get-switch-shadow($direction: 1) {
  box-shadow: 2px * $direction 0 5px rgba(0, 0, 0, 0.25);
}

.container {
  background-color: #3a3a45;
  border-radius: $border-radius;
  display: flex;
  position: relative;
  user-select: none;
  width: 300px;

  > .switch {
    @include get-switch-shadow(1);
    background-color: #0276ed;
    border-radius: $border-radius;
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: transform 0.3s, box-shadow 0.3s;
    width: 53%;
    z-index: 1;

    &.enabled {
      @include get-switch-shadow(-1);
      transform: translateX(90%);
    }
  }

  > .optionContainer {
    align-items: center;
    cursor: default;
    display: flex;
    flex: 1;
    justify-content: center;
    opacity: 1;
    padding: 15px 0;
    transition: opacity 0.3s;
    z-index: 2;

    &.selected {
      cursor: pointer;
      opacity: 0.5;
    }
  }
}
