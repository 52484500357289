@import '../../../styles/utils';

.container {
  background-color: #062241;
  border-radius: $border-radius-large;
  box-sizing: border-box;
  max-width: 200px;
  min-width: 150px;
  padding: getSpacing(2) getSpacing(3);
}

.dateContainer {
  display: flex;
  justify-content: flex-end;
}
