@import '../../../../shared/styles/utils';

.container,
.notesContainer {
  display: flex;
  flex-wrap: wrap;
}

.sectionContainer {
  margin-right: getSpacing(6);

  &:first-child {
    min-width: 40%;
  }

  &:last-child {
    margin-right: 0;
  }
}

.notesContainer .noteContainer {
  margin-bottom: getSpacing(2);
  margin-right: getSpacing(2);
}

@media only screen and (max-width: 790px) {
  .container {
    flex-direction: column;
  }

  .sectionContainer {
    margin-right: 0;
  }
}
