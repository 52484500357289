.doneContainer {
  align-items: center;
  animation: live-survey-done-enter 0.3s forwards;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  opacity: 0;
  transform: translateY(100px);

  > * {
    transform: scale(1.6);
  }
}

@keyframes live-survey-done-enter {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
