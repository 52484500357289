@import '../../../styles/utils';
@import '../../../styles/variables';

.button {
  min-width: $main-button-laptop-min-width !important;
  padding-bottom: getSpacing(1) !important;
  padding-top: getSpacing(1) !important;
}

@media only screen and (max-width: $tablet-breakpoint) {
  .button {
    min-width: $main-button-tablet-min-width !important;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .button {
    min-width: $main-button-mobile-min-width !important;
  }
}
