@import '../../styles/utils';

.container {
  align-items: center;
  background-color: #12161f;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left-color: $primary-color;
  border-left-style: solid;
  border-left-width: 8px;
  border-radius: $border-radius-large;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 150px;
  min-width: 100px;
  padding: getSpacing(1) getSpacing(2);
}
