// General:
$general-border-width: 1px;

// Colors:
$primary-color-old: #3dffd1;
$primary-color-old-dark: #379f85;

$primary-color: #054fee;
$primary-color-100: #4888fa;
$primary-color-300: #0016ba;
$primary-color-700: #062241;
$primary-color-800: #051833;
$dark-primary-color: #0d0e20;
$primary-highlight-color: #394376;

$alt-color-1: #9b4d6a;
$alt-color-2: #008677;
$alt-color-3: #ff774b;
$alt-color-4: #7948e6;

$background-color: #0f121a;
$light-background-color: #002243;
$lighter-background-color: #002243;

// Change:
$error-color: #f44336;
$default-disabled-color: #555763;

// Timing:
$shorter-time: 0.2s;
$short-time: 0.3s;
$normal-time: 0.6s;
$long-time: 1s;

// Spacing:
$spacing: 8px;

// Border:
$thin-border-width: 1px;
$border-primary-thin: $thin-border-width $primary-color-old solid;
$border-secondary-thin: $thin-border-width $dark-primary-color solid;

$normal-border-width: 3px;
$border-secondary-normal: $normal-border-width $dark-primary-color solid;

$thick-border-width: 4px;
$border-secondary-thick: $thick-border-width $dark-primary-color solid;

$thicker-border-width: 6px;
$border-secondary-thicker: $thicker-border-width $dark-primary-color solid;

// Border Radius:
$border-radius-small: 2px;
$border-radius-medium: 5px;
$border-radius-large: 10px;
$border-radius-larger: 20px;

// Icons:
$small-icon-font-size: 15px;
$medium-icon-font-size: 20px;
$large-icon-font-size: 25px;

// Box shadow:
$box-shadow-medium: 0 2px 8px rgb(0 0 0 / 40%);

// Logo:
$small-logo-width: 40px;
$medium-logo-width: 70px;
$large-logo-width: 100px;

// UserPhoto:
$user-photo-tiniest-size: 24px;
$user-photo-tiny-size: 30px;
$user-photo-small-size: 50px;
$user-photo-medium-size: 90px;
$user-photo-large-size: 140px;
$default-icon-color: #fff;

// Text:
$text-color: #fff;
$text-disabled-color: #697b8c;
$text-margin: 0.5em 0;
$text-on-light-color: #000;

// Text.Title:
$title-color: $text-color;
$title-font-size: 20px;
$title-font-weight: bold;

// Text.Label:
$label-color: $text-disabled-color;
$label-font-size: 12px;
$label-line-height: $label-font-size + 3px;
$label-font-weight: normal;

// Text.Paragraph:
$paragraph-color: $text-color;
$paragraph-font-size: 14px;
$paragraph-line-height: $paragraph-font-size + 7px;
$paragraph-font-weight: normal;

// Text.Highlight:
$highlight-color: #518aff;

// Button:
$button-border-radius: $border-radius-larger;
$main-button-laptop-min-width: $spacing * 41;
$main-button-tablet-min-width: $spacing * 35;
$main-button-mobile-min-width: $spacing * 30;

// Breakpoints
$mobile-breakpoint: 428px;
$tablet-breakpoint: 768px;
$laptop-breakpoint: 1024px;

// Survey question types
$survey-text-color: #fb5607;
$survey-slider-color: #00916e;
$survey-list-color: #3a86ff;
$survey-boolean-color: #8338ec;

// Survey editor
$survey-editor-panel-max-width: 50 * $spacing;

// Layout
$layout-max-width: 1300px;
$layout-padding: 2vw;

// Home layout
$home-aside-width: 350px;
$home-main-width: $layout-max-width - $home-aside-width;

// Global search
$global-search-width: $home-main-width * 0.5;

// Stat category colors
$trust-and-belonging-color: $alt-color-1;
$roadmap-visibility-color: $alt-color-2;
$burnout-likelihood-color: $alt-color-3;
$workflow-satisfaction-color: $alt-color-4;

// Light theme
$light-theme-background-color: #fbf4e6;
