@import '../../../styles/utils';

.container {
  background-color: #12161f;
  border: 1px solid #181f32;
  border-radius: 12px;
  display: flex;
  position: relative;
}

.chartContainer {
  display: flex;
  flex: 1;
  min-height: 300px;
}

.content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 35%;
  padding: getSpacing(2) $spacing $spacing;
}

.tipContainer {
  padding: getSpacing(2) getSpacing(2) getSpacing(2) 0;
}

.numberContainer {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 950px) {
  .container {
    flex-direction: column;

    > .content {
      margin: auto;
      max-width: 400px;
    }
  }
}
