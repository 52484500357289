@import '../../styles/variables';

.card {
  background-color: #12161f;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: $border-radius-large;
  box-shadow: $box-shadow-medium;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  position: relative;

  > * {
    min-width: 300px;
  }
}

.content {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .card {
    padding: 10px;

    > * {
      margin: auto;
      max-width: 250px;
      min-width: 200px;
    }
  }
}
