@import '../../../../shared/styles/utils';

.container {
  display: flex;
  max-height: 100vh;
  overflow-y: auto;

  > main {
    box-sizing: border-box;
    flex: 1;
    margin-left: auto;
    max-width: $home-main-width;
    padding: $layout-padding getSpacing(3) $layout-padding $layout-padding;
  }

  > aside {
    bottom: 0;
    box-sizing: border-box;
    margin-right: auto;
    padding: $layout-padding $layout-padding $layout-padding 0;
    position: sticky;
    right: 0;
    top: 0;
    width: $home-aside-width;
  }
}

.asideHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.homeStatsTitleContainer {
  opacity: 0;
}

.surveyContainer {
  padding-bottom: getSpacing(2);
}

@media only screen and (max-width: 950px) {
  .container {
    > main {
      padding: $layout-padding;
    }

    > aside {
      display: none;
    }
  }
}
