@import '../../../../styles/variables';

svg.verticalWave {
  height: 100%;
}

svg.horizontalWave {
  width: 100%;
}

.displayOnMobile {
  display: none;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .displayOnMobile {
    display: initial !important;
  }

  .hideOnMobile {
    display: none;
  }
}
