@import '../../../styles/utils';
@import '../../../styles/variables';

.button {
  border-radius: $button-border-radius !important;
  box-sizing: border-box !important;
  padding-left: getSpacing(3) !important;
  padding-right: getSpacing(3) !important;
  text-transform: none !important;

  &.secondary {
    border-color: #fff;
    color: #fff;

    &:hover {
      border-color: #ccc;
      color: #ccc;
    }
  }
}
