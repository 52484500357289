@import '../../../styles/utils';

.container {
  background-color: #0016ba;
  border-radius: $border-radius-larger;
  box-shadow: 0 0 12px rgba(2, 5, 23, 0.16);
  cursor: pointer;
  display: flex;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.03);
  }
}

.content {
  background-image: url('../../../../assets/images/tips/tipBackground.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: $border-radius-larger;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: getSpacing(2) 0 getSpacing(2) getSpacing(3);
}
