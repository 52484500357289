@import '../../styles/utils';

.container {
  background-color: #181f32;
  border-radius: $border-radius-large;
  padding: getSpacing(1) getSpacing(2);
}

.statContainer {
  align-items: center;
  display: flex;
}
