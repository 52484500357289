@import '../../../shared/styles/utils';

.container {
  background-color: #181d29;
  border: 1px solid #2f404f;
  border-radius: $border-radius-large;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: getSpacing(1) getSpacing(2);
  position: relative;
  transform: scale(1);
  transition: transform 0.3s, background-color 0.3s;
  user-select: none;

  &.active {
    background-color: $primary-color;
    cursor: default;
    transform: scale(1.05);
  }

  &:hover {
    transform: scale(1.05);
  }
}

.assetContainer {
  aspect-ratio: 30 / 44;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
}
