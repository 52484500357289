@import '../../../../shared/styles/utils';

.container {
  background-color: $lighter-background-color;
  border-radius: $border-radius-large;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: getSpacing(2);
  width: $survey-editor-panel-max-width;
}

main.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

footer.footer {
  display: flex;
  padding-top: getSpacing(2);
}

.repeatsContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
