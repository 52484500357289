@import '../../../../shared/styles/variables';

:export {
  arrowColor: $text-disabled-color;
}

.container {
  background-color: #12161f;
  border-radius: 15px;
  box-shadow: $box-shadow-medium;
  display: flex;
  flex-direction: column;
}

header.header {
  background-color: #181d29;
  border-radius: 15px 15px 0 0;
  display: flex;
  min-height: 50px;

  .dateContainer {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 2;
    justify-content: center;
  }

  .buttonContainer {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 1;
    padding: 0 20px;

    &:last-child {
      justify-content: flex-end;
      text-align: end;
    }
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  position: relative;
}

.mainSectionContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
  min-width: 67%;
  position: relative;
}

.numberContainer {
  > *:first-child {
    margin-top: 0;
  }

  > *:nth-child(2) {
    margin: 0;
  }
}

.sectionRow {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.section {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 105px;
  min-width: 250px;
  padding: 10px;
  width: 30%;

  > * {
    flex: 1;
  }
}
