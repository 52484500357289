@import './variables';

.smoothEnter,
.waitingForSmoothEnter {
  opacity: 0;
  transform: translateY($spacing * 3);
}

.smoothEnter {
  animation: smoothEnter $short-time $short-time forwards;
}

@keyframes smoothEnter {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
