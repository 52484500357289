@import '../../styles/utils';
@import '../../styles/variables';

$border-radius: $border-radius-larger;
$button-background-color: rgba(255, 255, 255, 0.1);

.container {
  background-color: #12161f;
  border: 1px solid #181f32;
  border-radius: $border-radius;
  display: flex;
  position: relative;
}

.verticalLayout {
  flex-direction: column;
  text-align: center;

  .imageContainer {
    border-radius: $border-radius $border-radius 0 0;
    min-height: 160px;
    width: 100%;
  }

  .button {
    border-radius: 0 0 $border-radius $border-radius;
  }
}

.imageContainer {
  background-color: #1e2433;
  border-radius: $border-radius 0 0 $border-radius;
  display: flex;
  min-height: 120px;
  min-width: 110px;
  width: 30%;

  > .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 1;
    margin: 20px 10px;
  }
}

.contentSection {
  flex: 1;
}

main.content {
  padding: getSpacing(2) getSpacing(3);
}

.button {
  align-items: center;
  background-color: $button-background-color;
  border-bottom-right-radius: $border-radius;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: getSpacing(1.5) 0;
  transition: background-color 0.3s;
  user-select: none;

  &:hover {
    background-color: darken($button-background-color, 25%);
  }
}
