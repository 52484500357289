@import '../../../../shared/styles/utils';

.container {
  background-color: #12161f;
  display: flex;
  flex-wrap: wrap;
  min-height: 320px;
  padding-left: getSpacing(3);
}

.summaryContainer {
  align-items: center;
  display: flex;
  flex: 1;
  max-width: 500px;
  min-width: 300px;
  padding: getSpacing(2) 0;

  > * {
    flex: 1;
  }
}

.chartContainer {
  display: flex;
  flex: 1.5;
  min-width: 400px;
}

.notesContainer {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  padding: getSpacing(2) 0 0 getSpacing(2);

  > * {
    flex: 1;
    margin-bottom: getSpacing(2);
    margin-right: getSpacing(2);
  }
}
