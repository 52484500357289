@import '../../styles/utils';

.inputContainer {
  align-items: center;
  background-color: #181f32;
  border: 1px solid transparent;
  border-radius: $border-radius-medium;
  cursor: text;
  display: flex;
  min-height: getSpacing(6);
  padding: 0 0 0 getSpacing(2);
  position: relative;
  transition: border-color 0.3s;

  &.onFocus {
    border-color: #4c5964;
  }
}

.input {
  align-self: normal;
  background-color: transparent;
  border: 0;
  color: #fff;
  flex: 1;
  font-family: GothamRound;
  font-size: 15px;
  outline: 0;
  padding: 0 getSpacing(2);
  transition: width 0.3s;
  width: 70%;

  &:focus {
    width: 100%;
  }
}

.closeContainer {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &.visibleCloseButton {
    opacity: 1;
    pointer-events: all;
  }
}

.shortcutWrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 0;

  .shortcutContainer {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    color: #fff;
    font-size: 9px;
    font-weight: bold;
    padding: 3px 6px;
    transition: opacity 0.3s;

    &.hiddenShortcut {
      opacity: 0;
    }

    &.macOsShortcut {
      font-size: 12px;
    }
  }
}
