@import '../../../../shared/styles/variables';
@import '../../../../shared/styles/utils';

$border-radius: $border-radius-larger;

.container {
  background-color: #12161f;
  border: 1px solid #181f32;
  border-radius: $border-radius;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.firstLevel {
  box-sizing: border-box;
  display: flex;
}

header.header {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  padding: 1.5vw 0 1.5vw 2vw;
}

.titleContainer {
  display: flex;
}

.surveyInfoContainer {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 2vw;

  .surveyInfoDivider {
    background-color: rgba(255, 255, 255, 0.1);
    height: 40px;
    margin: 0 5px;
    width: 1px;
  }
}

.actionabilityContainer {
  background-color: #1e212a;
  border-bottom-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 110px;
  justify-content: center;
  margin-bottom: getSpacing(2);
  margin-left: $spacing;
  max-width: 110px;
  padding: 0 2vw;
  transition: background-color 0.2s;

  .amountOfPeopleWhoAnsweredContainer {
    align-items: flex-end;
    display: flex;
  }

  &:hover {
    background-color: #082b51;
  }
}

.surveyInfoMobile {
  display: none;
}

.surveyInfoDesktop {
  display: flex;
}

@media only screen and (max-width: 1100px) {
  .surveyInfoMobile {
    display: flex;
  }

  .surveyInfoDesktop {
    display: none;
  }
}
